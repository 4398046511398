import { UploadedAssessmentResult } from '@sparx/api/apis/sparx/assessment/sitting/v1/sitting';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { createColumnHelper } from '@tanstack/react-table';
import { AccuracyLabelWithTooltip } from 'components/accuracy/accuracy';
import { useClassSelectionStudents } from 'components/header/NavigationControllerProvider';
import { PageHeader, PageHeaderSubpage } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { useReportingToggle } from 'components/reportingToggle';
import { DataTable } from 'components/table/DataTable';
import { PrettyTimestamp } from 'components/timestamp/PrettyTimestamp';
import { useSittingUploadedResultsMap } from 'queries/assessments';
import { useDailyProgress } from 'queries/reporting';
import { ReactNode, useMemo } from 'react';
import { useReportingPeriod } from 'utils/period';
import {
  StudentPages,
  useBooklogPageStats,
  useDailyProgressBetweenReportingPeriod,
} from 'utils/stats';

import styles from './ReportingView.module.css';

interface Row {
  student: Student;
  displayName: string;
  pages: StudentPages;
  readingAgeMonths?: number;
}

// Primary assessment name (secondary is 0e639198-3a16-11ef-8367-efdfd58def54)
const assessmentName = 'assessments/fa7d2420-6b78-11ef-834a-d3a68f240dc5';

const READING_AGE_MONTHS_ANNOTATION = 'assessment/reading-age-months';

const loadReadingAgeMonthsFromUploadedResults = (uploadedResults?: UploadedAssessmentResult[]) => {
  const latestResult = uploadedResults?.sort(
    (a, b) => (a.createdTimestamp?.seconds || 0) - (b.createdTimestamp?.seconds || 0),
  )?.[0];

  if (latestResult) {
    try {
      const readingAge = latestResult.package?.annotations?.[READING_AGE_MONTHS_ANNOTATION];
      if (readingAge) {
        return parseInt(readingAge || '0');
      }
    } catch (e) {
      console.error('Failed to parse reading age', e);
    }
  }
  return undefined;
};

export const ReportingView = () => {
  const { component, value: period } = useReportingToggle();
  const { start, end } = useReportingPeriod(period);

  const { data: dpData } = useDailyProgress({ suspense: true });
  const dpLogs = useDailyProgressBetweenReportingPeriod(dpData?.dailyProgress, start, end);
  const stats = useBooklogPageStats(dpLogs);

  const { students, selectedGroup } = useClassSelectionStudents();
  const { data: uploadedResults } = useSittingUploadedResultsMap(
    assessmentName,
    students.map(s => `students/${s.studentId}`),
    { suspense: true },
  );

  const rows: Row[] = useMemo(
    () =>
      students.map(student => {
        const readingAgeResults = uploadedResults?.get(student.studentId);
        const readingAgeMonths = loadReadingAgeMonthsFromUploadedResults(readingAgeResults);

        return {
          student,
          displayName: `${student.givenName} ${student.familyName}`,
          pages: stats[student.studentId] ?? {
            totalWords: 0,
            totalPages: 0,
            numberLogs: 0,
            carefulWordsRead: 0,
            carefulReadingTime: 0,
            questionsCorrect: 0,
            questionsTotal: 0,
            questionAccuracy: 0,
          },
          readingAgeMonths,
        };
      }),
    [students, stats, uploadedResults],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Row>();
    return [
      columnHelper.accessor(`displayName`, {
        id: 'name',
        header: 'Name',
        sortingFn: (a, b) =>
          // Sort by surname then first name
          a.original.student.familyName.localeCompare(b.original.student.familyName) ||
          a.original.student.givenName.localeCompare(b.original.student.givenName) ||
          a.original.student.studentId.localeCompare(b.original.student.studentId), // stability
        enableSorting: true,
        meta: {
          linkTo: (row: Row) => ({
            to: `/teacher/student/${row.student.studentId}/logs`,
            options: {
              state: { back: 'reporting' },
            },
          }),
        },
      }),
      columnHelper.accessor(`readingAgeMonths`, {
        header: 'Reading Age',
        cell: ({ getValue }) => monthsToDisplay(getValue()),
      }),
      columnHelper.accessor(`pages.questionAccuracy`, {
        id: 'accuracy',
        header: 'Accuracy',
        sortingFn: (a, b) => {
          if (
            Boolean(a.original.pages.questionsTotal) !== Boolean(b.original.pages.questionsTotal)
          ) {
            return a.original.pages.questionsTotal ? 1 : -1;
          }
          if (a.original.pages.questionAccuracy === b.original.pages.questionAccuracy) {
            return a.original.pages.questionsTotal - b.original.pages.questionsTotal;
          }
          return a.original.pages.questionAccuracy - b.original.pages.questionAccuracy;
        },
        cell: ({ row }) => (
          <AccuracyLabelWithTooltip
            total={row.original.pages.questionsTotal}
            correct={row.original.pages.questionsCorrect}
          />
        ),
        meta: {
          tooltip:
            'All the questions are simple to answer if the pupil is reading carefully and understands the basic plot and key points in the story.',
        },
      }),
      columnHelper.accessor(`pages.carefulReadingTime`, {
        id: 'carefulReadingTime',
        header: 'Reading Time',
        cell: ({ getValue }) => {
          const mins = Math.ceil(getValue());
          return <FormattedValue suffix={mins === 1 ? ' min' : ' mins'}>{mins}</FormattedValue>;
        },
        meta: {
          tooltip:
            'This is estimated using the number of words the pupil has read carefully, and the level of the book.',
        },
      }),
      columnHelper.accessor(`pages.carefulWordsRead`, {
        header: 'Words Read',
        cell: ({ getValue }) => <FormattedValue>{getValue()}</FormattedValue>,
      }),
      columnHelper.accessor(`pages.numberLogs`, {
        header: 'Number of logs',
        cell: ({ getValue }) => <FormattedValue>{getValue()}</FormattedValue>,
      }),
      columnHelper.accessor(`pages.totalPages`, {
        header: 'Pages Read',
        cell: ({ getValue }) => <FormattedValue>{getValue()}</FormattedValue>,
      }),
    ];
  }, []);

  let right;
  if (component) {
    right = (
      <span className={styles.ReportingPeriod}>
        <span>
          <PrettyTimestamp fmt={period === 'thisyear' ? 'do MMM yyyy' : 'p EEE do MMM'}>
            {start}
          </PrettyTimestamp>
        </span>
        -
        <span>
          <PrettyTimestamp fmt={period === 'thisyear' ? 'do MMM yyyy' : 'p EEE do MMM'}>
            {end}
          </PrettyTimestamp>
        </span>
        {component}
      </span>
    );
  }

  if (selectedGroup?.type !== 'group') {
    return <></>;
  }

  return (
    <PageContainer>
      <PageHeader title={`${selectedGroup.group.displayName} - Reporting`} right={right}>
        Reporting
        <PageHeaderSubpage>{selectedGroup.group.displayName}</PageHeaderSubpage>
      </PageHeader>
      <DataTable
        data={rows}
        columns={columns}
        defaultSort={[
          { id: 'accuracy', desc: false },
          { id: 'carefulReadingTime', desc: false },
          { id: 'name', desc: false },
        ]}
      />
    </PageContainer>
  );
};

const FormattedValue = ({
  children,
  prefix,
  suffix,
}: {
  children: number | undefined;
  prefix?: ReactNode;
  suffix?: ReactNode;
}) => {
  const display = children?.toLocaleString() ?? 0;
  return (
    <span className={!children ? styles.NoValue : undefined}>
      {prefix}
      {display}
      {suffix}
    </span>
  );
};

const monthsToDisplay = (months: number | undefined) => {
  if (!months) return '-';

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  return `${years}:${remainingMonths < 10 ? '0' : ''}${remainingMonths}`;
};
