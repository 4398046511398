import { UserProductData, UserType } from '@sparx/api/apis/sparx/landing/v1/landing';
import { QueryClient, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import {
  getSchoolIdFromSession,
  getSchoolIDFromUrl,
  redirectToLogin,
  userProductDataService,
} from 'api/auth';

interface Session extends UserProductData {
  schoolID: string;
}

const sessionQuery: UseQueryOptions<Session, Error> = {
  queryKey: ['session'],
  queryFn: async () => {
    const schoolID = await getSchoolIdFromSession();

    // Ensure that the school that is in the URL is the one that we currently have a session for.
    // If there is a mismatch then we redirect to the login page for the correct school.
    //
    // We do it here so that the session query never actually resolves, preventing the page from
    // loading and then redirecting straight away.
    const urlSchoolId = getSchoolIDFromUrl();
    if (!schoolID || (urlSchoolId && urlSchoolId !== schoolID)) {
      await redirectToLogin();
    }

    const data = await userProductDataService.getUserProductData({
      schoolName: `schools/${schoolID}`,
    }).response;

    return { ...data, schoolID: schoolID! };
  },
  cacheTime: Infinity,
  staleTime: Infinity,
};

export const useSession = (opts?: { suspense: boolean }) => useQuery({ ...sessionQuery, ...opts });

export const useIsSparxStaff = (opts?: { suspense: boolean }) => {
  const isSparxStaff = useIsActuallySparxStaff(opts);
  const { data } = useSparxStaffFeaturesEnabled();
  return isSparxStaff && data?.sparxFeaturesEnabled;
};

export const useIsActuallySparxStaff = (opts?: { suspense: boolean }) => {
  const { data: session } = useSession(opts);
  return session?.userType == UserType.SPARXSTAFF;
};

export const useToggleSparxStaffFeatures = (): [boolean, () => void] => {
  const { data: enabled } = useSparxStaffFeaturesEnabled();
  const queryClient = useQueryClient();
  const toggle = () => setSparxFeatures(queryClient, !enabled?.sparxFeaturesEnabled);
  return [enabled?.sparxFeaturesEnabled || false, toggle];
};

const sparxFeaturesLocalStorageKey = 'pri/sparxfeatures';

const useSparxStaffFeaturesEnabled = () =>
  useQuery({
    queryKey: ['session', 'sparx'],
    queryFn: () => ({
      sparxFeaturesEnabled: localStorage.getItem(sparxFeaturesLocalStorageKey) === 'on',
    }),
    cacheTime: Infinity,
    staleTime: Infinity,
  });

export const setSparxFeatures = (queryClient: QueryClient, on: boolean) => {
  if (on) {
    localStorage.setItem(sparxFeaturesLocalStorageKey, 'on');
  } else {
    localStorage.removeItem(sparxFeaturesLocalStorageKey);
  }
  queryClient.invalidateQueries(['session', 'sparx']);
};
