import { faArrowUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from '@sparx/design/components/button/Button';
import classNames from 'classnames';
import {
  useNavigationControlContext,
  useSelectedGroup,
} from 'components/header/NavigationControllerProvider';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
} from 'components/to-migrate/menu/Menu';
import { AnimatePresence, motion } from 'motion/react';
import { useMemo } from 'react';
import { useBookmarkGroups } from 'utils/groups';
import { getGroupId } from 'utils/routing';

import styles from './ClassSelect.module.css';

export const ClassSelect = () => {
  const { classSelectEnabled, selectedGroupId, cachedGroupId, setSelectedGroupId } =
    useNavigationControlContext();

  const groups = useBookmarkGroups({ suspense: true, enabled: classSelectEnabled });
  const selectedGroup = useSelectedGroup(selectedGroupId || cachedGroupId, groups);

  const ordered = useMemo(() => {
    return (
      groups?.sort((a, b) =>
        (a.displayName || '').localeCompare(b.displayName || '', undefined, { numeric: true }),
      ) || []
    );
  }, [groups]);

  const dropdown = (
    <motion.div
      initial={{ opacity: 0, marginLeft: -10 }}
      animate={{ opacity: 1, marginLeft: 0 }}
      exit={{ opacity: 0, marginLeft: -10 }}
      transition={{ type: 'spring', bounce: 0 }}
      style={{ position: 'relative', zIndex: 11 }}
    >
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger asChild>
          <Button
            rightIcon={<FontAwesomeIcon icon={faCaretDown} />}
            colourScheme="custom"
            className={styles.Button}
          >
            {!selectedGroup
              ? 'Select class'
              : selectedGroup?.type === 'yeargroup'
                ? selectedGroup.yearGroup.name
                : selectedGroup.group.displayName}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenuContent rounded="all" align="start" className={styles.Menu}>
          <div className={styles.MenuGroup}>
            <DropdownMenuLabel className={styles.GroupLabel}>Classes:</DropdownMenuLabel>
            {ordered.map(g => (
              <DropdownMenuItem
                key={g.name}
                onClick={() => setSelectedGroupId(getGroupId(g))}
                className={classNames(styles.Group, {
                  [styles.SelectedGroup]: selectedGroupId === getGroupId(g),
                })}
              >
                {g.displayName}
              </DropdownMenuItem>
            ))}
            {ordered.length === 0 && <span className={styles.NoClasses}>No classes</span>}
          </div>
        </DropdownMenuContent>
      </DropdownMenu.Root>
      <AnimatePresence>
        {!selectedGroup && (
          <motion.div
            initial={{ opacity: 0, transform: 'translateY(-10px)' }}
            animate={{ opacity: 1, transform: 'translateY(0px)' }}
            exit={{ opacity: 0, transform: 'translateY(-10px)' }}
            transition={{ type: 'spring', bounce: 0 }}
            className={styles.SelectWarning}
          >
            <FontAwesomeIcon icon={faArrowUp} />
            <span>Please select a class from the menu above.</span>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );

  return (
    <div className={styles.Container}>
      <AnimatePresence>{classSelectEnabled && dropdown}</AnimatePresence>
    </div>
  );
};
