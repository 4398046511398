import styles from 'components/accuracy/accuracy.module.css';

export type AccuracyLevel = 'Excellent' | 'Good' | 'Ok' | 'Low' | 'Very low';

export const AccuracyBackgroundStyles: Record<AccuracyLevel, string> = {
  Excellent: styles.ExcellentBackground,
  Good: styles.GoodBackground,
  Ok: styles.OkBackground,
  Low: styles.PoorBackground,
  'Very low': styles.BadBackground,
};

export const AccuracyTextStyles: Record<AccuracyLevel, string> = {
  Excellent: styles.ExcellentText,
  Good: styles.GoodText,
  Ok: styles.OkText,
  Low: styles.PoorText,
  'Very low': styles.BadText,
};

export const OrderedAccuracies: AccuracyLevel[] = ['Excellent', 'Good', 'Ok', 'Low', 'Very low'];
