// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/bookmark/reporting/v1/reporting.proto" (package "sparx.reading.bookmark.reporting.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import type { RpcTransport } from '@protobuf-ts/runtime-rpc';
import type { ServiceInfo } from '@protobuf-ts/runtime-rpc';
import { Reporting } from './reporting';
import type { GetBookmarksForUsersResponse } from './reporting';
import type { GetBookmarksForUsersRequest } from './reporting';
import type { QueryBooksResponse } from './reporting';
import type { QueryBooksRequest } from './reporting';
import type { ListUserBooksResponse } from './reporting';
import type { ListUserBooksRequest } from './reporting';
import type { ListUserDailyProgressResponse } from './reporting';
import type { ListUserDailyProgressRequest } from './reporting';
import type { ListUserBookLogsResponse } from './reporting';
import type { ListUserBookLogsRequest } from './reporting';
import { stackIntercept } from '@protobuf-ts/runtime-rpc';
import type { GetSchoolSettingsResponse } from './reporting';
import type { GetSchoolSettingsRequest } from './reporting';
import type { UnaryCall } from '@protobuf-ts/runtime-rpc';
import type { RpcOptions } from '@protobuf-ts/runtime-rpc';
/**
 * @generated from protobuf service sparx.reading.bookmark.reporting.v1.Reporting
 */
export interface IReportingClient {
  /**
   * @generated from protobuf rpc: GetSchoolSettings(sparx.reading.bookmark.reporting.v1.GetSchoolSettingsRequest) returns (sparx.reading.bookmark.reporting.v1.GetSchoolSettingsResponse);
   */
  getSchoolSettings(
    input: GetSchoolSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolSettingsRequest, GetSchoolSettingsResponse>;
  /**
   * @generated from protobuf rpc: ListUserBookLogs(sparx.reading.bookmark.reporting.v1.ListUserBookLogsRequest) returns (sparx.reading.bookmark.reporting.v1.ListUserBookLogsResponse);
   */
  listUserBookLogs(
    input: ListUserBookLogsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListUserBookLogsRequest, ListUserBookLogsResponse>;
  /**
   * @generated from protobuf rpc: ListUserDailyProgress(sparx.reading.bookmark.reporting.v1.ListUserDailyProgressRequest) returns (sparx.reading.bookmark.reporting.v1.ListUserDailyProgressResponse);
   */
  listUserDailyProgress(
    input: ListUserDailyProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<ListUserDailyProgressRequest, ListUserDailyProgressResponse>;
  /**
   * @generated from protobuf rpc: ListUserBooks(sparx.reading.bookmark.reporting.v1.ListUserBooksRequest) returns (sparx.reading.bookmark.reporting.v1.ListUserBooksResponse);
   */
  listUserBooks(
    input: ListUserBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListUserBooksRequest, ListUserBooksResponse>;
  /**
   * @generated from protobuf rpc: QueryBooks(sparx.reading.bookmark.reporting.v1.QueryBooksRequest) returns (sparx.reading.bookmark.reporting.v1.QueryBooksResponse);
   */
  queryBooks(
    input: QueryBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<QueryBooksRequest, QueryBooksResponse>;
  /**
   * @generated from protobuf rpc: GetBookmarksForUsers(sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersRequest) returns (sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersResponse);
   */
  getBookmarksForUsers(
    input: GetBookmarksForUsersRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBookmarksForUsersRequest, GetBookmarksForUsersResponse>;
}
/**
 * @generated from protobuf service sparx.reading.bookmark.reporting.v1.Reporting
 */
export class ReportingClient implements IReportingClient, ServiceInfo {
  typeName = Reporting.typeName;
  methods = Reporting.methods;
  options = Reporting.options;
  constructor(private readonly _transport: RpcTransport) {}
  /**
   * @generated from protobuf rpc: GetSchoolSettings(sparx.reading.bookmark.reporting.v1.GetSchoolSettingsRequest) returns (sparx.reading.bookmark.reporting.v1.GetSchoolSettingsResponse);
   */
  getSchoolSettings(
    input: GetSchoolSettingsRequest,
    options?: RpcOptions,
  ): UnaryCall<GetSchoolSettingsRequest, GetSchoolSettingsResponse> {
    const method = this.methods[0],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<GetSchoolSettingsRequest, GetSchoolSettingsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListUserBookLogs(sparx.reading.bookmark.reporting.v1.ListUserBookLogsRequest) returns (sparx.reading.bookmark.reporting.v1.ListUserBookLogsResponse);
   */
  listUserBookLogs(
    input: ListUserBookLogsRequest,
    options?: RpcOptions,
  ): UnaryCall<ListUserBookLogsRequest, ListUserBookLogsResponse> {
    const method = this.methods[1],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListUserBookLogsRequest, ListUserBookLogsResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: ListUserDailyProgress(sparx.reading.bookmark.reporting.v1.ListUserDailyProgressRequest) returns (sparx.reading.bookmark.reporting.v1.ListUserDailyProgressResponse);
   */
  listUserDailyProgress(
    input: ListUserDailyProgressRequest,
    options?: RpcOptions,
  ): UnaryCall<ListUserDailyProgressRequest, ListUserDailyProgressResponse> {
    const method = this.methods[2],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      ListUserDailyProgressRequest,
      ListUserDailyProgressResponse
    >('unary', this._transport, method, opt, input);
  }
  /**
   * @generated from protobuf rpc: ListUserBooks(sparx.reading.bookmark.reporting.v1.ListUserBooksRequest) returns (sparx.reading.bookmark.reporting.v1.ListUserBooksResponse);
   */
  listUserBooks(
    input: ListUserBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<ListUserBooksRequest, ListUserBooksResponse> {
    const method = this.methods[3],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<ListUserBooksRequest, ListUserBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: QueryBooks(sparx.reading.bookmark.reporting.v1.QueryBooksRequest) returns (sparx.reading.bookmark.reporting.v1.QueryBooksResponse);
   */
  queryBooks(
    input: QueryBooksRequest,
    options?: RpcOptions,
  ): UnaryCall<QueryBooksRequest, QueryBooksResponse> {
    const method = this.methods[4],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<QueryBooksRequest, QueryBooksResponse>(
      'unary',
      this._transport,
      method,
      opt,
      input,
    );
  }
  /**
   * @generated from protobuf rpc: GetBookmarksForUsers(sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersRequest) returns (sparx.reading.bookmark.reporting.v1.GetBookmarksForUsersResponse);
   */
  getBookmarksForUsers(
    input: GetBookmarksForUsersRequest,
    options?: RpcOptions,
  ): UnaryCall<GetBookmarksForUsersRequest, GetBookmarksForUsersResponse> {
    const method = this.methods[5],
      opt = this._transport.mergeOptions(options);
    return stackIntercept<
      GetBookmarksForUsersRequest,
      GetBookmarksForUsersResponse
    >('unary', this._transport, method, opt, input);
  }
}
