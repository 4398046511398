import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@sparx/design/components';
import classNames from 'classnames';
import styles from 'components/accuracy/accuracy.module.css';
import { FC } from 'react';

import { AccuracyTextStyles } from './types';
import { getAccuracyLevelFromCounts } from './utils';

export interface IAccuracyProps {
  correct: number | undefined;
  total: number | undefined;
}

export const AccuracyLabelWithTooltip: FC<IAccuracyProps> = ({ correct, total }) => {
  const accuracyLevel = getAccuracyLevelFromCounts(correct || 0, total || 0);

  let cell;
  if (total) {
    cell = (
      <Tooltip content={total ? `${correct}/${total}` : '-'}>
        <span className={classNames(styles.TooltipSpan, AccuracyTextStyles[accuracyLevel])}>
          {accuracyLevel}
          {accuracyLevel === 'Very low' && (
            <FontAwesomeIcon
              size={'sm'}
              className={classNames(styles.WarningTriangle)}
              icon={faExclamationTriangle}
            />
          )}
        </span>
      </Tooltip>
    );
  } else {
    cell = <span className={styles.NoAnswers}>No questions</span>;
  }

  return <div className={styles.FlexRow}>{cell}</div>;
};
