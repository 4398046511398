import {
  UserBookLog,
  UserDailyProgress,
} from '@sparx/api/apis/sparx/reading/bookmark/reporting/v1/reporting';
import { Group } from '@sparx/api/apis/sparx/teacherportal/groupsapi/v1/groupsapi';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp as pbTimestamp } from '@sparx/api/google/protobuf/timestamp';
import { Date as pbDate } from '@sparx/api/google/type/date';
import { format, isBefore, isToday } from 'date-fns';
import { useMemo } from 'react';
import { studentsInGroup } from 'utils/students';
import { DayMilestone, getMilestonesAchieved } from 'views/certificateview/milestones';

interface UserDailyProgressWithTimestamp {
  log: UserDailyProgress;
  timestamp: Date;
}

export const useLogsWithTimestamp = (
  logs: UserDailyProgress[] | undefined,
): UserDailyProgressWithTimestamp[] =>
  useMemo(
    () =>
      (logs || [])
        .filter(log => log.date)
        .map(log => ({
          log,
          timestamp: pbDate.toJsDate(log.date!),
        })),
    [logs],
  );

export const useLogsBetweenReportingPeriod = (
  logs: UserBookLog[] | undefined,
  start: Date,
  end: Date,
): UserBookLogWithTimestamp[] =>
  useMemo(
    () =>
      (logs || [])
        ?.map(log => {
          if (!log.timestamp) return null;

          // Only include logs between dates
          const timestamp = pbTimestamp.toDate(log.timestamp);
          if (timestamp < start || timestamp >= end) {
            return null;
          }

          return { log, timestamp };
        })
        .filter(v => !!v),
    [logs, start, end],
  );

export const dateToString = (date?: pbDate): string => {
  if (!date) return '0000-00-00';
  const monthPad = date.month < 10 ? '0' : '';
  const dayPad = date.day < 10 ? '0' : '';
  return `${date.year}-${monthPad}${date.month}-${dayPad}${date.day}`;
};

export const jsDateToString = (date: Date): string => format(date, 'yyyy-MM-dd');

export const useDailyProgressBetweenReportingPeriod = (
  logs: UserDailyProgress[] | undefined,
  start: Date,
  end: Date,
): UserDailyProgressWithTimestamp[] =>
  useMemo(
    () =>
      (logs || [])
        ?.map(log => {
          if (!log.date) return null;

          // Only include logs between dates
          const timestamp = pbDate.toJsDate(log.date);
          if (timestamp < start || timestamp >= end) {
            return null;
          }
          return { log, timestamp };
        })
        .filter(v => !!v),
    [logs, start, end],
  );

export const useDailyProgressStats = (logs: UserDailyProgressWithTimestamp[]) =>
  useMemo(() => {
    const studentReadToday: Record<string, boolean> = {};
    const studentLogsToday: Record<string, number> = {};
    const studentDaysRead: Record<string, Set<string>> = {};
    const studentLogs: Record<string, UserDailyProgress[]> = {};

    for (const { log, timestamp } of logs) {
      const today = isToday(timestamp);
      if (today) {
        studentLogsToday[log.userId] = log.logs;
      }

      // Don't count this day
      if (log.awards <= 0) continue;

      if (today) {
        studentReadToday[log.userId] = true;
      }
      const dayString = dateToString(log.date);

      if (!studentDaysRead[log.userId]) studentDaysRead[log.userId] = new Set<string>();
      studentDaysRead[log.userId].add(dayString);

      studentLogs[log.userId] = [...(studentLogs[log.userId] || []), log];
    }

    return { studentReadToday, studentDaysRead, studentLogsToday };
  }, [logs]);

export interface StudentPages {
  totalPages: number;
  numberLogs: number;

  carefulWordsRead: number;
  carefulReadingTime: number;
  questionsCorrect: number;
  questionsTotal: number;
  questionAccuracy: number;
}

export interface UserBookLogWithTimestamp {
  log: UserBookLog;
  timestamp: Date;
}

export const useBooklogPageStats = (dailyProgress?: UserDailyProgressWithTimestamp[]) =>
  useMemo(() => {
    const studentPagesRead: Record<string, StudentPages> = {};

    const ensureRecordExists = (userID: string) => {
      if (!studentPagesRead[userID])
        studentPagesRead[userID] = {
          totalPages: 0,
          numberLogs: 0,

          carefulWordsRead: 0,
          carefulReadingTime: 0,
          questionsCorrect: 0,
          questionsTotal: 0,
          questionAccuracy: 0,
        };
      return studentPagesRead[userID];
    };

    for (const { log } of dailyProgress || []) {
      const record = ensureRecordExists(log.userId);
      record.totalPages += log.pagesRead;
      record.numberLogs += log.logs;
      record.carefulWordsRead += log.wordsRead;
      record.carefulReadingTime += log.experience / 10;
      record.questionsCorrect += log.questionsCorrect;
      record.questionsTotal += log.questionsTotal;

      // Calculated each loop
      record.questionAccuracy =
        record.questionsTotal > 0 ? record.questionsCorrect / record.questionsTotal : 0;
    }
    return studentPagesRead;
  }, [dailyProgress]);

export interface GroupStat {
  group: Group;
  readToday: number;
  readTodayPercent: number;
  total: number;
  daysRead: { bad: number; okay: number; good: number };
}

export const useGroupStats = (
  groups?: Group[],
  students?: Student[],
  studentReadToday?: Record<string, boolean>,
  studentDaysRead?: Record<string, Set<string>>,
): GroupStat[] =>
  useMemo(() => {
    return (
      groups?.map(group => {
        const groupStudents = studentsInGroup(students || [], group.name);
        const readToday = groupStudents.filter(s => studentReadToday?.[s.studentId]).length;

        const daysRead = { bad: 0, okay: 0, good: 0 };
        for (const student of groupStudents) {
          const days = studentDaysRead?.[student.studentId]?.size || 0;

          if (days >= 3) daysRead.good++;
          else if (days > 0) daysRead.okay++;
          else daysRead.bad++;
        }

        const readTodayPercent = (readToday / groupStudents.length) * 100;

        return {
          group,
          readToday,
          readTodayPercent,
          total: groupStudents.length,
          daysRead,
        };
      }) || []
    );
  }, [groups, students, studentReadToday, studentDaysRead]);

const fakeMultiplier = 1;

interface UserWithDate {
  log: {
    userId: string;
  };
  timestamp: Date;
}

export const useStudentMilestonesToDate = (logs: UserWithDate[], date: Date) =>
  useMemo(() => {
    const dayTracker: Record<string, Set<string>> = {};
    const milestonesAchieved: Record<string, Map<DayMilestone, Date>> = {};

    // Ensure the logs are sorted by asc timestamp
    logs.sort((a, b) => (isBefore(a.timestamp, b.timestamp) ? -1 : 1));

    for (const { log, timestamp } of logs) {
      if (!isBefore(timestamp, date)) {
        continue; // ignore this log
      }
      const daysReadBefore = (dayTracker[log.userId]?.size || 0) * fakeMultiplier;

      const dayString = format(timestamp, 'yyyy-MM-dd');
      dayTracker[log.userId] = dayTracker[log.userId] || new Set<string>();
      dayTracker[log.userId].add(dayString);

      // Check if any milestones have been achieved
      const daysReadAfter = dayTracker[log.userId].size * fakeMultiplier;
      if (daysReadBefore != daysReadAfter) {
        const milestones = getMilestonesAchieved(daysReadBefore, daysReadAfter);
        for (const milestone of milestones) {
          milestonesAchieved[log.userId] =
            milestonesAchieved[log.userId] || new Map<DayMilestone, Date>();
          if (!milestonesAchieved[log.userId].get(milestone)) {
            milestonesAchieved[log.userId].set(milestone, timestamp);
          }
        }
      }
    }

    const days = new Map<string, number>();
    for (const [studentId, daysRead] of Object.entries(dayTracker)) {
      days.set(studentId, daysRead.size * fakeMultiplier);
    }

    return { milestones: milestonesAchieved, days };
  }, [logs, date]);
