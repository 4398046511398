import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InfoTooltip } from '@sparx/design/components';
import { useSessionStorage } from '@sparx/react-utils';
import {
  ColumnDef,
  ColumnSort,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import styles from 'components/table/DataTable.module.css';
import * as React from 'react';
import { ComponentProps, ReactNode, useMemo } from 'react';
import { Link, NavigateOptions } from 'react-router-dom';
import { cyrb53 } from 'utils/hash';

export interface DataTableProps<Data> extends ComponentProps<'table'> {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  defaultSort?: SortingState;
  onRowClick?: (row: Data) => void;
  getRowId?: (row: Data) => string;
  rowIsHighlighted?: (row: Data) => string | undefined;
  noDataRow?: React.ReactNode;
}

interface DataTableColumnMeta<Data extends object> {
  align?: 'left' | 'right' | 'center';
  linkTo?: (row: Data) => { to: string; options: NavigateOptions } | undefined;
  width?: string | number;
  tooltip?: ReactNode;
}

const useStoredSortingState = (
  key: string,
  defaultSort: SortingState,
): [SortingState, (sorting: ColumnSort[]) => void] => {
  const [sortingJSON, setSortingJSON] = useSessionStorage(key, JSON.stringify(defaultSort));
  const sorting = useMemo(() => {
    try {
      if (sortingJSON) {
        return JSON.parse(sortingJSON) as SortingState;
      }
    } catch {
      // ignore
    }
    return defaultSort;
  }, [defaultSort, sortingJSON]);

  return [sorting, (sorting: ColumnSort[]) => setSortingJSON(JSON.stringify(sorting))];
};

export function DataTable<Data extends object>({
  data,
  columns,
  defaultSort = [],
  getRowId,
  onRowClick,
  rowIsHighlighted,
  noDataRow,
  className,
  ...tableProps
}: DataTableProps<Data>) {
  // hash the columns to generate a key for the sorting state
  const hash = useMemo(() => {
    const keys = columns.map(c => c.id || c.header);
    const str = keys.join(',');
    return cyrb53(str);
  }, [columns]);
  const [sorting, setSorting] = useStoredSortingState('pri/table-sort/' + hash, defaultSort);

  const table = useReactTable({
    columns,
    data,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: sort => {
      const newSort = typeof sort === 'function' ? sort(sorting) : sort;
      setSorting(newSort);
    },
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <table className={classNames(styles.Table, className)} {...tableProps}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              let rowSpan = 1;
              if (header.isPlaceholder) {
                rowSpan = 2;
              }

              const isSubHeader = header.depth > 1;

              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: DataTableColumnMeta<Data> | undefined = header.column.columnDef.meta;

              const sortDesc = header.column.getIsSorted() === 'desc';

              return (
                <th
                  key={header.id}
                  onClick={
                    header.column.getCanSort() ? header.column.getToggleSortingHandler() : undefined
                  }
                  className={classNames(
                    isSubHeader && styles.HeaderCellSubHeader,
                    header.column.getCanSort() && styles.HeaderCellSortable,
                  )}
                  colSpan={header.colSpan}
                  rowSpan={rowSpan}
                  style={{ width: meta?.width }}
                >
                  {header.column.getCanSort() && (
                    <span
                      className={classNames(styles.SortIcon, {
                        [styles.SortIconActive]: header.column.getIsSorted(),
                      })}
                    >
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        aria-label="sorted descending"
                        style={{
                          transform: `rotate(${sortDesc ? 0 : -180}deg)`,
                        }}
                      />
                    </span>
                  )}
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {meta?.tooltip && (
                    <InfoTooltip ml={2} content={meta.tooltip} iconClassName={styles.InfoTooltip} />
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => (
          <tr
            key={row.id}
            onClick={e => {
              // If clicking a link in a cell, don't trigger onRowClick
              if (e.target instanceof HTMLAnchorElement) return;
              onRowClick?.(row.original);
            }}
            className={classNames(
              styles.Cell,
              onRowClick && styles.RowClickable,
              rowIsHighlighted?.(row.original) && styles.RowHighlighted,
            )}
          >
            {row.getVisibleCells().map(cell => {
              // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
              const meta: DataTableColumnMeta<Data> | undefined = cell.column.columnDef.meta;
              const link = meta?.linkTo?.(row.original);

              let content = flexRender(cell.column.columnDef.cell, cell.getContext());
              if (link) {
                content = (
                  <Link key={cell.id} to={link.to} {...link.options} className={styles.CellLink}>
                    {content}
                  </Link>
                );
              }
              return <td key={cell.id}>{content}</td>;
            })}
          </tr>
        ))}
        {noDataRow && table.getRowModel().rows.length === 0 && (
          <tr>
            <td colSpan={table.getAllColumns().length}>{noDataRow}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
