import { LoadingSpinner } from '@sparx/design/components';
import { createColumnHelper } from '@tanstack/react-table';
import { PageHeader } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { ProgressBar } from 'components/progressbar/ProgressBar';
import { useReportingToggle } from 'components/reportingToggle';
import { DataTable } from 'components/table/DataTable';
import { useDailyProgress } from 'queries/reporting';
import { useStudents } from 'queries/students';
import queryString from 'query-string';
import { Suspense, useMemo } from 'react';
import { useBookmarkGroups } from 'utils/groups';
import { useReportingPeriod } from 'utils/period';
import { getGroupRouteQuery } from 'utils/routing';
import {
  GroupStat,
  useDailyProgressBetweenReportingPeriod,
  useDailyProgressStats,
  useGroupStats,
} from 'utils/stats';

import styles from './HomeView.module.css';

export const HomeView = () => {
  return (
    <PageContainer>
      <PageHeader>Home</PageHeader>
      <Suspense fallback={<LoadingSpinner />}>
        <GroupTable />
      </Suspense>
    </PageContainer>
  );
};

const GroupTable = () => {
  const { component, value: period } = useReportingToggle();
  const { start, end } = useReportingPeriod(period);

  const { data: logs } = useDailyProgress({ suspense: true });
  const dpLogs = useDailyProgressBetweenReportingPeriod(logs?.dailyProgress, start, end);
  const { studentReadToday, studentDaysRead } = useDailyProgressStats(dpLogs);

  const groups = useBookmarkGroups({ suspense: true });
  const { data: students } = useStudents({ suspense: true });

  const stats = useGroupStats(groups, students, studentReadToday, studentDaysRead);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<GroupStat>();
    return [
      columnHelper.accessor(`group.displayName`, {
        header: 'Class',
        enableSorting: true,
        meta: {
          linkTo: (row: GroupStat) => ({
            to: `/teacher/tracking?${queryString.stringify({ ...getGroupRouteQuery(row.group) })}`,
          }),
        },
      }),
      columnHelper.accessor(`readTodayPercent`, {
        header: 'Read today',
        enableSorting: true,
        cell: ({ getValue }) => <>{Math.floor(getValue())}%</>,
      }),
      columnHelper.accessor(`daysRead.good`, {
        header: 'Days read',
        enableSorting: true,
        id: 'daysread',
        sortingFn: (a, b) =>
          a.original.daysRead.good - b.original.daysRead.good ||
          a.original.daysRead.okay - b.original.daysRead.okay ||
          a.original.daysRead.bad - b.original.daysRead.bad,
        cell: ({ row }) => (
          <ProgressBar
            total={row.original.total}
            bars={[
              { value: row.original.daysRead.good, color: 'var(--leaf-green-80)' },
              { value: row.original.daysRead.okay, color: 'var(--marigold-yellow-80)' },
              { value: row.original.daysRead.bad, color: 'var(--carbon-grey-10)' },
            ]}
          />
        ),
      }),
    ];
  }, []);

  return (
    <div className={styles.TableContainer}>
      <div className={styles.TableHeader}>
        <div className={styles.TableKey}>
          <div className={styles.KeyItem}>
            <div className={styles.KeyColor} style={{ backgroundColor: 'var(--leaf-green-80)' }} />
            <span>3+ days</span>
          </div>
          <div className={styles.KeyItem}>
            <div
              className={styles.KeyColor}
              style={{ backgroundColor: 'var(--marigold-yellow-80)' }}
            />
            <span>1-2 days</span>
          </div>
          <div className={styles.KeyItem}>
            <div className={styles.KeyColor} style={{ backgroundColor: 'var(--carbon-grey-10)' }} />
            <span>0 days</span>
          </div>
        </div>
        {component}
      </div>
      <DataTable data={stats} columns={columns} defaultSort={[{ id: 'daysread', desc: false }]} />
    </div>
  );
};
