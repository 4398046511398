import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@sparx/design/components';
import React, { createRef, PropsWithChildren, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import styles from './PageHeader.module.css';

interface PageHeaderProps {
  back?: string | (() => void);
  right?: React.ReactNode;
  title?: string;
}

export const PageHeader = ({
  children,
  back,
  right,
  title,
}: PropsWithChildren<PageHeaderProps>) => {
  let pageTitle = title;
  if (!title && typeof children === 'string') {
    pageTitle = children;
  }

  return (
    <div className={styles.Container}>
      <Title title={pageTitle} />
      {back && (
        <Button
          variant="primary"
          colourScheme="custom"
          aria-label="Back"
          {...(typeof back === 'function'
            ? { onClick: back }
            : {
                as: Link,
                to: back,
              })}
          className={styles.BackButton}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      )}
      <h2>{children}</h2>
      {right && <div className={styles.RightContent}>{right}</div>}
    </div>
  );
};

export const Title = ({ title }: { title?: string }) => {
  const productName = 'Sparx Primary';
  return (
    <Helmet>
      <title>{title ? `${title} - ${productName}` : productName}</title>
    </Helmet>
  );
};

export const PageHeaderSubpage = ({
  children,
  before,
  to,
}: PropsWithChildren<{ before?: boolean; to?: string | (() => void) }>) => {
  const component = (
    <span className={styles.Subpage}>
      {!before && <span className={styles.Slash}>/</span>}
      {children}
      {before && <span className={styles.Slash}>/</span>}
    </span>
  );
  if (to) {
    if (typeof to === 'string') {
      return (
        <Link to={to} className={styles.SubpageLink}>
          {component}
        </Link>
      );
    } else {
      return (
        <span onClick={to} className={styles.SubpageLink}>
          {component}
        </span>
      );
    }
  }
  return component;
};

export const PageFooter = ({ children }: PropsWithChildren) => {
  const ref = createRef<HTMLElement>();

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        ([e]) => e.target.toggleAttribute('data-stuck', e.intersectionRatio < 1),
        { threshold: [1] },
      );
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref]);

  return (
    <footer ref={ref} className={styles.PageFooter}>
      <div className={styles.PageFooterContent}>{children}</div>
    </footer>
  );
};
