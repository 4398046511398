// @generated by protobuf-ts 2.4.0 with parameter long_type_number,generate_dependencies,optimize_code_size,ts_nocheck,// @generated from protobuf file "apis/sparx/reading/bookmark/v1/appserver.proto" (package "sparx.reading.bookmark.v1", syntax proto3),// tslint:disable
// @ts-nocheck
import { ServiceType } from '@protobuf-ts/runtime-rpc';
import { MessageType } from '@protobuf-ts/runtime';
import { UserType } from '../../../types/user';
import { Book } from '../../books/v1/book';
import { Timestamp } from '../../../../../google/protobuf/timestamp';
import { SilverReader } from '../../books/v1/book';
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.BookConfig
 */
export interface BookConfig {
  /**
   * @generated from protobuf field: string isbn = 1;
   */
  isbn: string;
  /**
   * @generated from protobuf field: int32 start_page = 2;
   */
  startPage: number;
  /**
   * @generated from protobuf field: int32 end_page = 3;
   */
  endPage: number;
  /**
   * @generated from protobuf field: bool has_config = 4;
   */
  hasConfig: boolean;
  /**
   * @generated from protobuf field: sparx.reading.books.v1.SilverReader silver_reader_config = 5;
   */
  silverReaderConfig?: SilverReader;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.BookState
 */
export interface BookState {
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.BookConfig config = 1;
   */
  config?: BookConfig;
  /**
   * @generated from protobuf field: int32 last_read_page = 2;
   */
  lastReadPage: number;
  /**
   * @generated from protobuf field: string answer_history_string = 3;
   */
  answerHistoryString: string;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_answered_timestamp = 4;
   */
  lastAnsweredTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: int32 correct_answers = 5;
   */
  correctAnswers: number;
  /**
   * @generated from protobuf field: int32 total_answers = 6;
   */
  totalAnswers: number;
  /**
   * Banked pages is the number of pages the student has read
   * before getting questions. When the student answers questions
   * this count will be cleared.
   *
   * @generated from protobuf field: int32 banked_pages = 7;
   */
  bankedPages: number;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.BookRating
 */
export interface BookRating {
  /**
   * @generated from protobuf field: int32 enjoyment_rating = 1;
   */
  enjoymentRating: number;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.BookLog
 */
export interface BookLog {
  /**
   * @generated from protobuf field: int32 start_page = 1;
   */
  startPage: number;
  /**
   * @generated from protobuf field: int32 end_page = 2;
   */
  endPage: number;
  /**
   * @generated from protobuf field: bool finished_book = 3;
   */
  finishedBook: boolean;
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.BookLog.Stats stats = 4;
   */
  stats?: BookLog_Stats;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.BookLog.Stats
 */
export interface BookLog_Stats {
  /**
   * @generated from protobuf field: int32 correct_answers = 1;
   */
  correctAnswers: number;
  /**
   * @generated from protobuf field: int32 total_answers = 2;
   */
  totalAnswers: number;
  /**
   * Calculated end page is set when the student was returned
   * to a different page to the one they set as the end page.
   *
   * @generated from protobuf field: int32 calculated_end_page = 3;
   */
  calculatedEndPage: number;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.UserBook
 */
export interface UserBook {
  /**
   * @generated from protobuf field: string book_name = 1;
   */
  bookName: string;
  /**
   * @generated from protobuf field: int32 read_index = 2;
   */
  readIndex: number;
  /**
   * @generated from protobuf field: double progress = 3;
   */
  progress: number;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp started_timestamp = 4;
   */
  startedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp last_read_timestamp = 5;
   */
  lastReadTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp finished_timestamp = 6;
   */
  finishedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp swapped_timestamp = 7;
   */
  swappedTimestamp?: Timestamp;
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.BookRating rating = 8;
   */
  rating?: BookRating;
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.BookState state = 9;
   */
  state?: BookState;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.UserBookWithMetadata
 */
export interface UserBookWithMetadata {
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.UserBook user_book = 1;
   */
  userBook?: UserBook;
  /**
   * @generated from protobuf field: sparx.reading.books.v1.Book book = 2;
   */
  book?: Book;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.AbortPage
 */
export interface AbortPage {
  /**
   * @generated from protobuf field: int32 page = 1;
   */
  page: number;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.UserDailyProgressState
 */
export interface UserDailyProgressState {
  /**
   * @generated from protobuf field: map<string, sparx.reading.bookmark.v1.UserDailyProgressState.Log> logs = 1;
   */
  logs: {
    [key: string]: UserDailyProgressState_Log;
  };
  /**
   * @generated from protobuf field: int32 pages_read = 2;
   */
  pagesRead: number;
  /**
   * @generated from protobuf field: int32 words_read = 3;
   */
  wordsRead: number;
  /**
   * @generated from protobuf field: int32 questions_correct = 4;
   */
  questionsCorrect: number;
  /**
   * @generated from protobuf field: int32 questions_total = 5;
   */
  questionsTotal: number;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.UserDailyProgressState.Log
 */
export interface UserDailyProgressState_Log {
  /**
   * @generated from protobuf field: int32 experience = 1;
   */
  experience: number;
  /**
   * @generated from protobuf field: int32 pages_read = 2;
   */
  pagesRead: number;
  /**
   * @generated from protobuf field: int32 banked_pages = 3;
   */
  bankedPages: number;
  /**
   * @generated from protobuf field: string experience_capped_reason = 4;
   */
  experienceCappedReason: string;
  /**
   * @generated from protobuf field: int32 words_read = 5;
   */
  wordsRead: number;
  /**
   * @generated from protobuf field: int32 questions_correct = 6;
   */
  questionsCorrect: number;
  /**
   * @generated from protobuf field: int32 questions_total = 7;
   */
  questionsTotal: number;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.UserDaysLogged
 */
export interface UserDaysLogged {
  /**
   * @generated from protobuf field: int32 total_days_logged = 1;
   */
  totalDaysLogged: number;
  /**
   * @generated from protobuf field: int32 stickers_earned = 2;
   */
  stickersEarned: number;
  /**
   * @generated from protobuf field: int32 days_towards_next_sticker = 3;
   */
  daysTowardsNextSticker: number;
  /**
   * @generated from protobuf field: int32 days_per_sticker = 4;
   */
  daysPerSticker: number;
  /**
   * @generated from protobuf field: bool logged_today = 5;
   */
  loggedToday: boolean;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GetCurrentUserRequest
 */
export interface GetCurrentUserRequest {}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GetCurrentUserResponse
 */
export interface GetCurrentUserResponse {
  /**
   * ID of the user.
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * The user's first name.
   *
   * @generated from protobuf field: string given_name = 2;
   */
  givenName: string;
  /**
   * The user's type.
   * Deprecated: use api_user_type instead.
   *
   * @deprecated
   * @generated from protobuf field: string user_type = 3 [deprecated = true];
   */
  userType: string;
  /**
   * Stats on the user's reading.
   *
   * @generated from protobuf field: sparx.reading.bookmark.v1.UserDaysLogged days_logged = 4;
   */
  daysLogged?: UserDaysLogged;
  /**
   * The user's type
   *
   * @generated from protobuf field: sparx.types.UserType api_user_type = 5;
   */
  apiUserType: UserType;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GetBookRequest
 */
export interface GetBookRequest {
  /**
   * @generated from protobuf field: string isbn = 1;
   */
  isbn: string;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GetBookResponse
 */
export interface GetBookResponse {
  /**
   * @generated from protobuf field: sparx.reading.books.v1.Book book = 1;
   */
  book?: Book;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.AssignBookRequest
 */
export interface AssignBookRequest {
  /**
   * Resource name of the book to assign. In the format
   * `books/{book_id}`.
   *
   * @generated from protobuf field: string book_name = 1;
   */
  bookName: string;
  /**
   * ISBN of the book that was used to find the book.
   *
   * @generated from protobuf field: string isbn = 2;
   */
  isbn: string;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.AssignBookResponse
 */
export interface AssignBookResponse {
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.UserBookWithMetadata book = 1;
   */
  book?: UserBookWithMetadata;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.ListUserBooksRequest
 */
export interface ListUserBooksRequest {}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.ListUserBooksResponse
 */
export interface ListUserBooksResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookmark.v1.UserBookWithMetadata books = 1;
   */
  books: UserBookWithMetadata[];
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.LogReadingRequest
 */
export interface LogReadingRequest {
  /**
   * @generated from protobuf field: string book_name = 1;
   */
  bookName: string;
  /**
   * @generated from protobuf field: int32 start_page = 2;
   */
  startPage: number;
  /**
   * @generated from protobuf field: int32 end_page = 3;
   */
  endPage: number;
  /**
   * @generated from protobuf field: bool finished_book = 4;
   */
  finishedBook: boolean;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.LogReadingResponse
 */
export interface LogReadingResponse {
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.UserBook user_book = 1;
   */
  userBook?: UserBook;
  /**
   * @generated from protobuf field: string package_name = 2;
   */
  packageName: string;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.RateBookRequest
 */
export interface RateBookRequest {
  /**
   * @generated from protobuf field: string book_name = 1;
   */
  bookName: string;
  /**
   * @generated from protobuf field: optional int32 enjoyment_rating = 2;
   */
  enjoymentRating?: number;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.RateBookResponse
 */
export interface RateBookResponse {
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.UserBook user_book = 1;
   */
  userBook?: UserBook;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.UpdateBookSwapStateRequest
 */
export interface UpdateBookSwapStateRequest {
  /**
   * @generated from protobuf field: string book_name = 1;
   */
  bookName: string;
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.SwapState swap_state = 2;
   */
  swapState: SwapState;
  /**
   * @generated from protobuf field: string swap_reason = 3;
   */
  swapReason: string;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.UpdateBookSwapStateResponse
 */
export interface UpdateBookSwapStateResponse {
  /**
   * @generated from protobuf field: sparx.reading.bookmark.v1.UserBook user_book = 1;
   */
  userBook?: UserBook;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GetQuestionAudioRequest
 */
export interface GetQuestionAudioRequest {
  /**
   * @generated from protobuf field: string activity_name = 1;
   */
  activityName: string;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.AudioClip
 */
export interface AudioClip {
  /**
   * @generated from protobuf field: string phrase = 1;
   */
  phrase: string;
  /**
   * @generated from protobuf field: bytes audio_data = 2;
   */
  audioData: Uint8Array;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GetQuestionAudioResponse
 */
export interface GetQuestionAudioResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookmark.v1.AudioClip audio_clips = 1;
   */
  audioClips: AudioClip[];
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GetSchoolCompletionSummaryRequest
 */
export interface GetSchoolCompletionSummaryRequest {}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GroupSummary
 */
export interface GroupSummary {
  /**
   *  string group_id = 1;
   *
   * @generated from protobuf field: string group_title = 2;
   */
  groupTitle: string;
  /**
   * @generated from protobuf field: repeated sparx.reading.bookmark.v1.GroupSummary.Student students = 3;
   */
  students: GroupSummary_Student[];
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GroupSummary.Student
 */
export interface GroupSummary_Student {
  /**
   *    string user_id = 1;
   *
   * @generated from protobuf field: bool read_today = 2;
   */
  readToday: boolean;
  /**
   * @generated from protobuf field: int32 days_read = 3;
   */
  daysRead: number;
}
/**
 * @generated from protobuf message sparx.reading.bookmark.v1.GetSchoolCompletionSummaryResponse
 */
export interface GetSchoolCompletionSummaryResponse {
  /**
   * @generated from protobuf field: repeated sparx.reading.bookmark.v1.GroupSummary groups = 1;
   */
  groups: GroupSummary[];
}
/**
 * @generated from protobuf enum sparx.reading.bookmark.v1.SwapState
 */
export enum SwapState {
  /**
   * @generated from protobuf enum value: SWAP_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: SWAP_STATE_SWAP = 1;
   */
  SWAP = 1,
  /**
   * @generated from protobuf enum value: SWAP_STATE_UNSWAP = 2;
   */
  UNSWAP = 2,
}
// @generated message type with reflection information, may provide speed optimized methods
class BookConfig$Type extends MessageType<BookConfig> {
  constructor() {
    super('sparx.reading.bookmark.v1.BookConfig', [
      { no: 1, name: 'isbn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'start_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'end_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 4, name: 'has_config', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 5,
        name: 'silver_reader_config',
        kind: 'message',
        T: () => SilverReader,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.BookConfig
 */
export const BookConfig = new BookConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookState$Type extends MessageType<BookState> {
  constructor() {
    super('sparx.reading.bookmark.v1.BookState', [
      { no: 1, name: 'config', kind: 'message', T: () => BookConfig },
      {
        no: 2,
        name: 'last_read_page',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'answer_history_string',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'last_answered_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 5,
        name: 'correct_answers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 6,
        name: 'total_answers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'banked_pages',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.BookState
 */
export const BookState = new BookState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookRating$Type extends MessageType<BookRating> {
  constructor() {
    super('sparx.reading.bookmark.v1.BookRating', [
      {
        no: 1,
        name: 'enjoyment_rating',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.BookRating
 */
export const BookRating = new BookRating$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLog$Type extends MessageType<BookLog> {
  constructor() {
    super('sparx.reading.bookmark.v1.BookLog', [
      { no: 1, name: 'start_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'end_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'finished_book',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 4, name: 'stats', kind: 'message', T: () => BookLog_Stats },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.BookLog
 */
export const BookLog = new BookLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BookLog_Stats$Type extends MessageType<BookLog_Stats> {
  constructor() {
    super('sparx.reading.bookmark.v1.BookLog.Stats', [
      {
        no: 1,
        name: 'correct_answers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'total_answers',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'calculated_end_page',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.BookLog.Stats
 */
export const BookLog_Stats = new BookLog_Stats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserBook$Type extends MessageType<UserBook> {
  constructor() {
    super('sparx.reading.bookmark.v1.UserBook', [
      { no: 1, name: 'book_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'read_index', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'progress', kind: 'scalar', T: 1 /*ScalarType.DOUBLE*/ },
      { no: 4, name: 'started_timestamp', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'last_read_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      {
        no: 6,
        name: 'finished_timestamp',
        kind: 'message',
        T: () => Timestamp,
      },
      { no: 7, name: 'swapped_timestamp', kind: 'message', T: () => Timestamp },
      { no: 8, name: 'rating', kind: 'message', T: () => BookRating },
      { no: 9, name: 'state', kind: 'message', T: () => BookState },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.UserBook
 */
export const UserBook = new UserBook$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserBookWithMetadata$Type extends MessageType<UserBookWithMetadata> {
  constructor() {
    super('sparx.reading.bookmark.v1.UserBookWithMetadata', [
      { no: 1, name: 'user_book', kind: 'message', T: () => UserBook },
      { no: 2, name: 'book', kind: 'message', T: () => Book },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.UserBookWithMetadata
 */
export const UserBookWithMetadata = new UserBookWithMetadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AbortPage$Type extends MessageType<AbortPage> {
  constructor() {
    super('sparx.reading.bookmark.v1.AbortPage', [
      { no: 1, name: 'page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.AbortPage
 */
export const AbortPage = new AbortPage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserDailyProgressState$Type extends MessageType<UserDailyProgressState> {
  constructor() {
    super('sparx.reading.bookmark.v1.UserDailyProgressState', [
      {
        no: 1,
        name: 'logs',
        kind: 'map',
        K: 9 /*ScalarType.STRING*/,
        V: { kind: 'message', T: () => UserDailyProgressState_Log },
      },
      { no: 2, name: 'pages_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'words_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'questions_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 5,
        name: 'questions_total',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.UserDailyProgressState
 */
export const UserDailyProgressState = new UserDailyProgressState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserDailyProgressState_Log$Type extends MessageType<UserDailyProgressState_Log> {
  constructor() {
    super('sparx.reading.bookmark.v1.UserDailyProgressState.Log', [
      { no: 1, name: 'experience', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 2, name: 'pages_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 3,
        name: 'banked_pages',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'experience_capped_reason',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'words_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 6,
        name: 'questions_correct',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 7,
        name: 'questions_total',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.UserDailyProgressState.Log
 */
export const UserDailyProgressState_Log = new UserDailyProgressState_Log$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserDaysLogged$Type extends MessageType<UserDaysLogged> {
  constructor() {
    super('sparx.reading.bookmark.v1.UserDaysLogged', [
      {
        no: 1,
        name: 'total_days_logged',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 2,
        name: 'stickers_earned',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 3,
        name: 'days_towards_next_sticker',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      {
        no: 4,
        name: 'days_per_sticker',
        kind: 'scalar',
        T: 5 /*ScalarType.INT32*/,
      },
      { no: 5, name: 'logged_today', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.UserDaysLogged
 */
export const UserDaysLogged = new UserDaysLogged$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentUserRequest$Type extends MessageType<GetCurrentUserRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.GetCurrentUserRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GetCurrentUserRequest
 */
export const GetCurrentUserRequest = new GetCurrentUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentUserResponse$Type extends MessageType<GetCurrentUserResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.GetCurrentUserResponse', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'given_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'user_type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'days_logged', kind: 'message', T: () => UserDaysLogged },
      {
        no: 5,
        name: 'api_user_type',
        kind: 'enum',
        T: () => ['sparx.types.UserType', UserType],
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GetCurrentUserResponse
 */
export const GetCurrentUserResponse = new GetCurrentUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookRequest$Type extends MessageType<GetBookRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.GetBookRequest', [
      { no: 1, name: 'isbn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GetBookRequest
 */
export const GetBookRequest = new GetBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBookResponse$Type extends MessageType<GetBookResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.GetBookResponse', [
      { no: 1, name: 'book', kind: 'message', T: () => Book },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GetBookResponse
 */
export const GetBookResponse = new GetBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignBookRequest$Type extends MessageType<AssignBookRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.AssignBookRequest', [
      { no: 1, name: 'book_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'isbn', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.AssignBookRequest
 */
export const AssignBookRequest = new AssignBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssignBookResponse$Type extends MessageType<AssignBookResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.AssignBookResponse', [
      { no: 1, name: 'book', kind: 'message', T: () => UserBookWithMetadata },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.AssignBookResponse
 */
export const AssignBookResponse = new AssignBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBooksRequest$Type extends MessageType<ListUserBooksRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.ListUserBooksRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.ListUserBooksRequest
 */
export const ListUserBooksRequest = new ListUserBooksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBooksResponse$Type extends MessageType<ListUserBooksResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.ListUserBooksResponse', [
      {
        no: 1,
        name: 'books',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => UserBookWithMetadata,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.ListUserBooksResponse
 */
export const ListUserBooksResponse = new ListUserBooksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogReadingRequest$Type extends MessageType<LogReadingRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.LogReadingRequest', [
      { no: 1, name: 'book_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'start_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      { no: 3, name: 'end_page', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
      {
        no: 4,
        name: 'finished_book',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.LogReadingRequest
 */
export const LogReadingRequest = new LogReadingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogReadingResponse$Type extends MessageType<LogReadingResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.LogReadingResponse', [
      { no: 1, name: 'user_book', kind: 'message', T: () => UserBook },
      {
        no: 2,
        name: 'package_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.LogReadingResponse
 */
export const LogReadingResponse = new LogReadingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateBookRequest$Type extends MessageType<RateBookRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.RateBookRequest', [
      { no: 1, name: 'book_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'enjoyment_rating',
        kind: 'scalar',
        opt: true,
        T: 5 /*ScalarType.INT32*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.RateBookRequest
 */
export const RateBookRequest = new RateBookRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RateBookResponse$Type extends MessageType<RateBookResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.RateBookResponse', [
      { no: 1, name: 'user_book', kind: 'message', T: () => UserBook },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.RateBookResponse
 */
export const RateBookResponse = new RateBookResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBookSwapStateRequest$Type extends MessageType<UpdateBookSwapStateRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.UpdateBookSwapStateRequest', [
      { no: 1, name: 'book_name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'swap_state',
        kind: 'enum',
        T: () => [
          'sparx.reading.bookmark.v1.SwapState',
          SwapState,
          'SWAP_STATE_',
        ],
      },
      {
        no: 3,
        name: 'swap_reason',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.UpdateBookSwapStateRequest
 */
export const UpdateBookSwapStateRequest = new UpdateBookSwapStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBookSwapStateResponse$Type extends MessageType<UpdateBookSwapStateResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.UpdateBookSwapStateResponse', [
      { no: 1, name: 'user_book', kind: 'message', T: () => UserBook },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.UpdateBookSwapStateResponse
 */
export const UpdateBookSwapStateResponse =
  new UpdateBookSwapStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuestionAudioRequest$Type extends MessageType<GetQuestionAudioRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.GetQuestionAudioRequest', [
      {
        no: 1,
        name: 'activity_name',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GetQuestionAudioRequest
 */
export const GetQuestionAudioRequest = new GetQuestionAudioRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AudioClip$Type extends MessageType<AudioClip> {
  constructor() {
    super('sparx.reading.bookmark.v1.AudioClip', [
      { no: 1, name: 'phrase', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'audio_data', kind: 'scalar', T: 12 /*ScalarType.BYTES*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.AudioClip
 */
export const AudioClip = new AudioClip$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetQuestionAudioResponse$Type extends MessageType<GetQuestionAudioResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.GetQuestionAudioResponse', [
      {
        no: 1,
        name: 'audio_clips',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => AudioClip,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GetQuestionAudioResponse
 */
export const GetQuestionAudioResponse = new GetQuestionAudioResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolCompletionSummaryRequest$Type extends MessageType<GetSchoolCompletionSummaryRequest> {
  constructor() {
    super('sparx.reading.bookmark.v1.GetSchoolCompletionSummaryRequest', []);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GetSchoolCompletionSummaryRequest
 */
export const GetSchoolCompletionSummaryRequest =
  new GetSchoolCompletionSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupSummary$Type extends MessageType<GroupSummary> {
  constructor() {
    super('sparx.reading.bookmark.v1.GroupSummary', [
      {
        no: 2,
        name: 'group_title',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 3,
        name: 'students',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GroupSummary_Student,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GroupSummary
 */
export const GroupSummary = new GroupSummary$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GroupSummary_Student$Type extends MessageType<GroupSummary_Student> {
  constructor() {
    super('sparx.reading.bookmark.v1.GroupSummary.Student', [
      { no: 2, name: 'read_today', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 3, name: 'days_read', kind: 'scalar', T: 5 /*ScalarType.INT32*/ },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GroupSummary.Student
 */
export const GroupSummary_Student = new GroupSummary_Student$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSchoolCompletionSummaryResponse$Type extends MessageType<GetSchoolCompletionSummaryResponse> {
  constructor() {
    super('sparx.reading.bookmark.v1.GetSchoolCompletionSummaryResponse', [
      {
        no: 1,
        name: 'groups',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => GroupSummary,
      },
    ]);
  }
}
/**
 * @generated MessageType for protobuf message sparx.reading.bookmark.v1.GetSchoolCompletionSummaryResponse
 */
export const GetSchoolCompletionSummaryResponse =
  new GetSchoolCompletionSummaryResponse$Type();
/**
 * @generated ServiceType for protobuf service sparx.reading.bookmark.v1.App
 */
export const App = new ServiceType('sparx.reading.bookmark.v1.App', [
  {
    name: 'GetCurrentUser',
    options: {},
    I: GetCurrentUserRequest,
    O: GetCurrentUserResponse,
  },
  { name: 'GetBook', options: {}, I: GetBookRequest, O: GetBookResponse },
  {
    name: 'AssignBook',
    options: {},
    I: AssignBookRequest,
    O: AssignBookResponse,
  },
  {
    name: 'ListUserBooks',
    options: {},
    I: ListUserBooksRequest,
    O: ListUserBooksResponse,
  },
  { name: 'RateBook', options: {}, I: RateBookRequest, O: RateBookResponse },
  {
    name: 'UpdateBookSwapState',
    options: {},
    I: UpdateBookSwapStateRequest,
    O: UpdateBookSwapStateResponse,
  },
  {
    name: 'LogReading',
    options: {},
    I: LogReadingRequest,
    O: LogReadingResponse,
  },
  {
    name: 'GetQuestionAudio',
    options: {},
    I: GetQuestionAudioRequest,
    O: GetQuestionAudioResponse,
  },
]);
/**
 * @generated ServiceType for protobuf service sparx.reading.bookmark.v1.ReportingApp
 */
export const ReportingApp = new ServiceType(
  'sparx.reading.bookmark.v1.ReportingApp',
  [
    {
      name: 'GetSchoolCompletionSummary',
      options: {},
      I: GetSchoolCompletionSummaryRequest,
      O: GetSchoolCompletionSummaryResponse,
    },
  ],
);
