import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faBookmark,
  faCertificate,
  faChartBar,
  faChartLine,
  faEllipsis,
  faHome,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { DropdownMenuContent, DropdownMenuItem } from 'components/to-migrate/menu/Menu';
import { useIsSparxStaff } from 'queries/sessions';
import { PropsWithChildren } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './Sidebar.module.css';

export const Sidebar = ({ children }: PropsWithChildren) => {
  const isSparxStaff = useIsSparxStaff({ suspense: true });

  return (
    <div className={styles.OuterContainer}>
      <div className={styles.InnerContainer}>
        <div className={styles.Sidebar}>
          <Button icon={faHome} href="/teacher" text="Home" />
          <Button icon={faChartBar} href="/teacher/tracking" text="Tracking" />
          <Button icon={faChartLine} href="/teacher/reporting" text="Reporting" />
          <MoreMenu />
        </div>
        <div className={styles.Content}>{children}</div>
      </div>
    </div>
  );
};

interface ButtonProps {
  icon: IconDefinition;
  text: string;
  href: string;
}

const Button = ({ icon, href, text }: ButtonProps) => {
  const location = useLocation();
  const active = location.pathname === href;

  return (
    <Link to={href} className={classNames(styles.Button, { [styles.ButtonSelected]: active })}>
      <div className={styles.ButtonIcon}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={classNames(styles.ButtonText, { [styles.ButtonTextTight]: text.length > 9 })}>
        {text}
      </div>
    </Link>
  );
};

const MoreMenu = () => {
  const isSparxStaff = useIsSparxStaff({ suspense: true });

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>
        <div className={classNames(styles.Button, { [styles.ButtonSelected]: false })}>
          <div className={styles.ButtonIcon}>
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
          <div className={styles.ButtonText}>More</div>
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenuContent
        rounded="from-top-left"
        side="right"
        align="start"
        className={styles.MoreMenu}
      >
        <Link to="/teacher/bookmarks" style={{ textDecoration: 'none' }}>
          <DropdownMenuItem icon={faBookmark}>Bookmarks</DropdownMenuItem>
        </Link>
        {isSparxStaff && (
          <Link to="/teacher/certificates" style={{ textDecoration: 'none' }}>
            <DropdownMenuItem icon={faCertificate}>Certificates</DropdownMenuItem>
          </Link>
        )}
        {/*<Link to="/teacher/staff" style={{ textDecoration: 'none' }}>*/}
        {/*  <DropdownMenuItem icon={faGraduationCap}>Staff Manager</DropdownMenuItem>*/}
        {/*</Link>*/}
      </DropdownMenuContent>
    </DropdownMenu.Root>
  );
};
