import { faCheck, faShuffle, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListUserBooksResponse_UserBook,
  UserBookLog,
  UserDailyProgress,
} from '@sparx/api/apis/sparx/reading/bookmark/reporting/v1/reporting';
import { UserBook } from '@sparx/api/apis/sparx/reading/bookmark/v1/appserver';
import { Student } from '@sparx/api/apis/sparx/teacherportal/studentapi/v1/studentapi';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { NotFound } from 'app/ErrorPages';
import classNames from 'classnames';
import { AccuracyLabelWithTooltip } from 'components/accuracy/accuracy';
import { PageHeader } from 'components/pageheader/PageHeader';
import { PageContainer } from 'components/pages/PageContainer';
import { format, startOfDay } from 'date-fns';
import { useBookMetadataSuspense } from 'queries/content';
import { useDailyProgress, useUserBookLogs, useUserBooks } from 'queries/reporting';
import { useStudents } from 'queries/students';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useUserUserBooks } from 'utils/books';
import { getBookCoverUrl } from 'utils/image';
import { dateToString, jsDateToString, UserBookLogWithTimestamp } from 'utils/stats';

import styles from './StudentSummary.module.css';

export const StudentSummary = () => {
  const { data: logs } = useUserBookLogs({ suspense: true });
  const { data: students = [] } = useStudents({ suspense: true });
  const { data: books } = useUserBooks({ suspense: true });
  const { data: dailyProgress } = useDailyProgress({ suspense: true });

  const { studentID = '' } = useParams();
  const student = students.find(s => s.studentId == studentID);

  const userBooks = useUserUserBooks(books?.userBooks || [], studentID);

  const studentLogs = useMemo(
    () =>
      logs?.userBookLogs
        .filter(log => log.userId == studentID)
        .sort((a, b) => (b.timestamp?.seconds || 0) - (a.timestamp?.seconds || 0)),
    [logs, studentID],
  );

  const studentDailyProgress = useMemo(
    () =>
      dailyProgress?.dailyProgress
        .filter(dp => dp.userId === studentID)
        .reduce(
          (acc, dp) => {
            if (dp.date) {
              const dateStr = dateToString(dp.date);
              acc[dateStr] = dp;
            }
            return acc;
          },
          {} as Record<string, UserDailyProgress>,
        ),
    [dailyProgress, studentID],
  );

  if (!student) {
    return <NotFound />;
  }

  return (
    <Summary
      student={student}
      logs={studentLogs || []}
      userBooks={userBooks}
      dailyProgress={studentDailyProgress || {}}
    />
  );
};

interface SummaryProps {
  student: Student;
  logs: UserBookLog[];
  userBooks: ListUserBooksResponse_UserBook[];
  dailyProgress: Record<string, UserDailyProgress>;
}

const Summary = ({ student, logs, userBooks, dailyProgress }: SummaryProps) => {
  const recentBooks = useMemo(
    () =>
      userBooks
        .sort(
          // Sort by most recently read
          (a, b) =>
            (b.userBook?.lastReadTimestamp?.seconds || 0) -
            (a.userBook?.lastReadTimestamp?.seconds || 0),
        )
        .slice(0, 4),
    [userBooks],
  );

  const groupedLogs = useMemo(() => groupLogsByDate(logs), [logs]);

  const { state } = useLocation();
  const backLocation = state?.back === 'reporting' ? '/teacher/reporting' : '/teacher/tracking';

  return (
    <PageContainer>
      <PageHeader back={backLocation}>
        {student.givenName} {student.familyName}
      </PageHeader>

      <div className={styles.Block}>
        <h3>Latest books</h3>
        <div className={styles.BooksWrapper}>
          <div className={styles.BooksContainer}>
            <div className={styles.Books}>
              {recentBooks.map(userBook => {
                if (!userBook.userBook) return null; // ignore
                return <RecentBook key={userBook.userBook.bookName} userBook={userBook.userBook} />;
              })}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Block}>
        <h3>Reading History</h3>
        <ul className={styles.Logs}>
          {groupedLogs.length === 0 && <li>No reading history</li>}
          {groupedLogs.map(date => (
            <DailyProgress
              key={date.date.toISOString()}
              date={date}
              dailyProgress={dailyProgress[jsDateToString(date.date)]}
            />
          ))}
        </ul>
      </div>
    </PageContainer>
  );
};

const DailyProgress = ({
  date,
  dailyProgress,
}: {
  date: DateLogs;
  dailyProgress?: UserDailyProgress;
}) => {
  const carefullyRead = (dailyProgress?.awards || 0) > 0;

  return (
    <li>
      <div className={styles.Timeline}>
        <div
          className={classNames(styles.TimelineChip, {
            [styles.TimelineChipCompleted]: carefullyRead,
          })}
        >
          {carefullyRead && <FontAwesomeIcon icon={faCheck} />}
        </div>
      </div>
      <div className={styles.LogContent}>
        <div>
          <strong>{format(date.date, 'EEEE do MMM')}</strong>
          {carefullyRead && (
            <span className={styles.CarefullyRead}>Some careful reading has been completed</span>
          )}
        </div>
        {date.books.map((book, i) => (
          <div key={`${book.bookName}/${i}`} className={styles.DateBook}>
            <div className={styles.DateBookHeader}>
              <BookLogHeader name={book.bookName} />
            </div>
            <ul className={styles.DateBookLogs}>
              {book.logs.map(({ log, timestamp }, j) => {
                const pageCount = (log.state?.endPage || 0) - (log.state?.startPage || 0);
                return (
                  <li key={j}>
                    <div>
                      p{log.state?.startPage} - p{log.state?.endPage}
                    </div>
                    <div>
                      {pageCount} page{pageCount !== 1 && 's'}
                    </div>
                    <strong>
                      {log.state?.stats?.totalAnswers ? (
                        <AccuracyLabelWithTooltip
                          correct={log.state.stats.correctAnswers}
                          total={log.state.stats.totalAnswers}
                        />
                      ) : (
                        <span className={styles.NoQuestionsAnswered}>No questions answered</span>
                      )}
                    </strong>
                    <div>{format(timestamp, 'h:mm aaa')}</div>
                  </li>
                );
              })}
            </ul>
          </div>
        ))}
      </div>
    </li>
  );
};

const groupLogsByDate = (logs: UserBookLog[]): DateLogs[] => {
  const dateLogs: DateLogs[] = [];

  logs.sort((a, b) => (a.timestamp?.seconds || 0) - (b.timestamp?.seconds || 0));

  for (const log of logs) {
    if (!log.timestamp) continue; // ignore

    const timestamp = Timestamp.toDate(log.timestamp);
    const day = startOfDay(timestamp);

    let latestDateLog = dateLogs[0];
    if (!latestDateLog || latestDateLog.date.toISOString() !== day.toISOString()) {
      dateLogs.unshift({ date: day, books: [] });
      latestDateLog = dateLogs[0];
    }

    let latestBookLog = latestDateLog.books[0];
    if (!latestBookLog || latestBookLog.bookName !== log.bookName) {
      latestDateLog.books.unshift({ bookName: log.bookName, logs: [] });
      latestBookLog = latestDateLog.books[0];
    }

    latestBookLog.logs.unshift({ timestamp, log });
  }

  // add in the missing days between the dateLogs
  // for (const )

  console.log(dateLogs);
  return dateLogs;
};

interface DateLogs {
  date: Date;
  books: DateBookLogs[];
}

interface DateBookLogs {
  bookName: string;
  logs: UserBookLogWithTimestamp[];
}

const RecentBook = ({ userBook }: { userBook: UserBook }) => {
  const { data: book } = useBookMetadataSuspense(userBook.bookName);

  if (!book) {
    return <span className={styles.BookTitle}>Unknown book</span>;
  }

  const bookCoverImage = getBookCoverUrl(book);

  return (
    <div key={userBook.bookName} className={styles.Book}>
      {bookCoverImage ? (
        <img alt="" src={bookCoverImage} />
      ) : (
        <div className={styles.PlaceholderImage} />
      )}
      <div className={styles.BookInfo}>
        <div className={styles.BookTitle}>{book.title}</div>
        <div className={styles.BookAuthors}>{book.authors.join(', ')}</div>

        {Boolean(book.wordCount?.count) && (
          <div className={styles.BookStat}>
            <span className={styles.BookStatLabel}>Word count:</span>
            {book.wordCount?.count.toLocaleString()}
          </div>
        )}
        <div className={classNames(styles.BookStat, styles.BookStatProgress)}>
          {userBook.swappedTimestamp ? (
            <span className={styles.BookStatSwapped}>
              <FontAwesomeIcon icon={faShuffle} />
              Swapped
            </span>
          ) : (
            <>
              {userBook.finishedTimestamp && (
                <FontAwesomeIcon icon={faCheck} className={styles.BookStatComplete} />
              )}
              <span className={styles.BookStatProgress}>
                {Math.round(userBook.progress * 100)}%
              </span>
            </>
          )}
        </div>
        <div className={styles.BookStats}>
          {Boolean(userBook.state?.totalAnswers) && (
            <div className={styles.BookStat}>
              <span className={styles.BookStatLabel}>Accuracy:</span>
              <AccuracyLabelWithTooltip
                correct={userBook.state?.correctAnswers}
                total={userBook.state?.totalAnswers}
              />
            </div>
          )}
          {!!userBook.rating?.enjoymentRating && (
            <div className={styles.BookStat}>
              <span className={styles.BookStatLabel}>Student rating:</span>
              <span className={styles.BookStatRating}>
                {userBook.rating?.enjoymentRating / 2}/5
              </span>
              <FontAwesomeIcon icon={faStar} className={styles.BookStatStar} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const BookLogHeader = ({ name }: { name: string }) => {
  const { data: book } = useBookMetadataSuspense(name);
  if (!book) {
    return <span className={styles.BookTitle}>Unknown book</span>;
  }

  return (
    <>
      <img alt="" src={getBookCoverUrl(book)} />
      <span>{book.title}</span>
    </>
  );
};
