import { useQuery } from '@tanstack/react-query';
import { getSchoolIdFromSession, reportingClient } from 'api/auth';

export const useUserBookLogs = (opts?: { suspense?: boolean }) =>
  useQuery({
    queryKey: ['bookmark', 'logs'],
    queryFn: async () =>
      reportingClient.listUserBookLogs({
        schoolName: `schools/${await getSchoolIdFromSession()}`,
      }).response,
    ...opts,
  });

export const useUserBooks = (opts?: { suspense?: boolean }) =>
  useQuery({
    queryKey: ['bookmark', 'books'],
    queryFn: async () =>
      reportingClient.listUserBooks({
        schoolName: `schools/${await getSchoolIdFromSession()}`,
      }).response,
    ...opts,
  });

export const useUserBookmarks = (subjects: string[], opts?: { suspense?: boolean }) =>
  useQuery({
    queryKey: ['bookmark', 'bookmarks', subjects.sort()],
    queryFn: async () =>
      reportingClient.getBookmarksForUsers({
        schoolName: `schools/${await getSchoolIdFromSession()}`,
        subjects,
      }).response,
    staleTime: Infinity,
    ...opts,
  });

export const useDailyProgress = (opts?: { suspense?: boolean }) =>
  useQuery({
    queryKey: ['bookmark', 'dailyLogs'],
    queryFn: async () =>
      reportingClient.listUserDailyProgress({
        schoolName: `schools/${await getSchoolIdFromSession()}`,
      }).response,
    ...opts,
  });
