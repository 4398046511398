import { AccuracyLevel } from './types';

export const getAccuracyLevel = (accuracy: number): AccuracyLevel => {
  if (accuracy >= 88) return 'Excellent';
  if (accuracy >= 76) return 'Good';
  if (accuracy >= 60) return 'Ok';
  if (accuracy >= 50) return 'Low';
  return 'Very low';
};

export const getAccuracyLevelFromCounts = (correct: number, total: number): AccuracyLevel => {
  const decimalAccuracy = !total ? 0 : correct / total;
  const accuracy = Math.round(decimalAccuracy * 100);

  // overrides
  if (correct === 1 && total === 1) return 'Good';
  if (correct === 2 && total === 2) return 'Good';
  if (correct === 1 && total === 2) return 'Ok';

  return getAccuracyLevel(accuracy);
};
